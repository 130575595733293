import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/DashBoard/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        action: 'read',
        resource: 'Dashboard',
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/events',
      name: 'events',
      component: () => import('@/views/Events/Events.vue'),
      meta: {
        requiresAuth: true,
        resource: 'Events',
        action: 'read',
      }
    },
    {
      path: '/events/edit',
      name: 'edit-event',
      component: () => import('@/views/Events/Edit/EditEvent.vue'),
      meta: {
        requiresAuth: true,
        resource: 'EditEvent',
        action: 'edit',
      }
    },
    {
      path: '/events/create',
      name: 'create-event',
      component: () => import('@/views/Events/Create/CreateEvent.vue'),
      meta: {
        requiresAuth: true,
        resource: 'AddEvent',
        action: 'create',
      }
    },
    {
      path: '/events/persons',
      name: 'persons',
      component: () => import('@/views/Persons/Persons.vue'),
      meta: {
        requiresAuth: true,
        resource: 'Persons',
        action: 'read',
      }
    },
    {
      path: '/events/persons/details',
      name: 'details',
      component: () => import('@/views/Persons/Edit/DetailsEditPerson.vue'),
      meta: {
        requiresAuth: true,
        resource: 'EditPerson',
        action: 'edit',
      }
    },
    {
      path: '/events/persons/create',
      name: 'create-person',
      component: () => import('@/views/Persons/Create/CreatePerson.vue'),
      meta: {
        requiresAuth: true,
        resource: 'AddPerson',
        action: 'create',
      }
    },
    {
      path: '/events/persons/importfiles',
      name: 'importfiles',
      component: () => import('@/views/Persons/ImportFiles.vue'),
      meta: {
        requiresAuth: true,
        resource: 'ImportPersons',
        action: 'import',
      }
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import('@/views/Companies/Companies.vue'),
      meta: {
        requiresAuth: true,
        resource: 'Companies',
        action: 'read',
      }
    },
    {
      path: '/companies/create',
      name: 'create-company',
      component: () => import('@/views/Companies/Create/CreateCompany.vue'),
      meta: {
        requiresAuth: true,
        resource: 'AddCompany',
        action: 'create',
      }
    },
    {
      path: '/companies/edit',
      name: 'edit-company',
      component: () => import('@/views/Companies/Edit/EditCompany.vue'),
      meta: {
        requiresAuth: true,
        resource: 'EditCompany',
        action: 'edit',
      }
    },
    {
      path: '/aforoacces',
      name: 'aforoacces',
      component: () => import('@/views/AforoAcces/AforoAcces.vue'),
      meta: {
        requiresAuth: true,
        resource: 'AforoAccess',
        action: 'read',
      }
    },
    {
      path: '/aforoacces/create',
      name: 'create-aforoacces',
      component: () => import('@/views/AforoAcces/Create/CreateAforoAcces.vue'),
      meta: {
        requiresAuth: true,
        resource: 'AddAforoAccess',
        action: 'create',
      }
    },
    {
      path: '/aforoacces/edit',
      name: 'edit-aforoacces',
      component: () => import('@/views/AforoAcces/Edit/EditAforoAcces.vue'),
      meta: {
        requiresAuth: true,
        resource: 'EditAforoAccess',
        action: 'edit',
      }
    },
    {
      path: '/aforodoor',
      name: 'aforodoor',
      component: () => import('@/views/AforoDoor/AforoDoor.vue'),
      meta: {
        requiresAuth: true,
        resource: 'AforoDoor',
        action: 'read',
      }
    },
    {
      path: '/aforodoor/create',
      name: 'create-aforodoor',
      component: () => import('@/views/AforoDoor/Create/CreateAforoDoor.vue'),
      meta: {
        requiresAuth: true,
        resource: 'AddAforoDoor',
        action: 'create',
      }
    },
    {
      path: '/aforodoor/edit',
      name: 'edit-aforodoor',
      component: () => import('@/views/AforoDoor/Edit/EditAforoDoor.vue'),
      meta: {
        requiresAuth: true,
        resource: 'EditAforoDoor',
        action: 'edit',
      }
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Users/Users.vue'),
      meta: {
        requiresAuth: true,
        resource: 'Users',
        action: 'read',
      }
    },
    {
      path: '/users/create',
      name: 'create-user',
      component: () => import('@/views/Users/Create/CreateUser.vue'),
      meta: {
        requiresAuth: true,
        resource: 'AddUser',
        action: 'create',
      }
    },
    {
      path: '/users/edit',
      name: 'edit-user',
      component: () => import('@/views/Users/Edit/EditUser.vue'),
      meta: {
        requiresAuth: true,
        resource: 'EditUser',
        action: 'edit',
      }
    },
    {
      path: '/usergroup',
      name: 'user-group',
      component: () => import('@/views/UserGroup/UserGroup.vue'),
      meta: {
        requiresAuth: true,
        resource: 'UserGroup',
        action: 'read',
      }
    },
    {
      path: '/usergroup/create',
      name: 'create-user-group',
      component: () => import('@/views/UserGroup/Create/CreateUserGroup.vue'),
      meta: {
        requiresAuth: true,
        resource: 'AddUserGroup',
        action: 'create',
      }
    },
    {
      path: '/usergroup/edit',
      name: 'edit-user-group',
      component: () => import('@/views/UserGroup/Edit/EditUserGroup.vue'),
      meta: {
        requiresAuth: true,
        resource: 'EditUserGroup',
        action: 'edit',
      }
    },
    {
      path: '/import-persons/:idEvent',
      name: 'import-persons',
      component: () => import('@/views/ImportPersons/ImportFiles.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
