import Vue from 'vue'

// axios
import axios from 'axios'
import apiRouter from "../router/apiRouter";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: apiRouter,
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  httpsAgent: {rejectUnauthorized: false},
  headers: {'x-access-token': localStorage.getItem("accessToken")}
  
})

Vue.prototype.$http = axiosIns

export default axiosIns
